<template functionnal>
  <div class="template">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Soutenez-nous !</h1>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h6>
            Envie de devenir formateur bénévole au sein de Femmes Initiatives ?
          </h6>

          <div>
            Envoyez nous votre CV et précisez vos disponibilités et vos motivations à l’adresse suivante : femmes.initiatives@free.fr
          </div>
        </div>

        <div class="col-12 mt-8">
          <h6>
            Envie de faire un don
          </h6>

          <button class="btn-primary btn-xlarge">
            <router-link class="white" to="/contact" target='_blank'>Nous contacter</router-link>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
